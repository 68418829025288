.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* editing popup form has extra box on the right  */
/* hacky way to hide it */
.dx-datagrid-edit-popup-form
    > .dx-layout-manager
    > .dx-collection
    > .dx-box-flex
    > .dx-box-item
    > .dx-item-content.dx-collection
    > .dx-box-item:nth-child(2) {
    flex: 0 !important;
}

.dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title {
    background-color: #222ba7;
    color: #fff;
}

.dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title .dx-button-content .dx-icon {
    color: #fff;
}

table.dx-datagrid-table tbody tr.dx-header-row,
table.dx-treelist-table tbody tr.dx-header-row {
    background-color: #008000;
    color: #fff;
}

:where(.css-dev-only-do-not-override-h817iw).ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: rgb(208, 227, 255);
}

.dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-bottom .dx-button[aria-label='Save'] {
    background-color: #008000;
    color: #fff;
}

.dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-bottom .dx-button[aria-label='Cancel'] {
    background-color: #ff0000;
    color: #fff;
}

.ant-layout-sider .ant-menu-title-content {
    font-size: 13px;
    letter-spacing: -0.3px;
}

.dx-datagrid {
    font-size: 12px;
}
